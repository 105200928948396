import React from 'react';
import { Link } from 'gatsby';

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://twitter.com/brandinavian" rel="noopener" target="_blank" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/brandinavian/" rel="noopener" target="_blank" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/brandinavianusa/" rel="noopener" target="_blank" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                <li><a href="https://www.linkedin.com/company/brandinavian/" rel="noopener" target="_blank" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; {new Date().getFullYear()} Brandinavian</li><li><Link to="/contact">Get in touch <i className="icon fa-hand-peace-o"></i></Link></li>
            </ul>
        </div>
    </footer>
)

export default Footer
