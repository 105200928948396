import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu" onClick={props.onToggleMenu}>
        <div className="inner" onClick={props.nonToggleClick}>
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/web-development">Development</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/about">About</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/articles">Articles</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><Link className="button special fit" onClick={props.onToggleMenu} to="/contact">Get in touch</Link></li>
                <li><a href="#" className="button fit">Danish</a></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func,
    nonToggleClick: PropTypes.func
}

export default Menu
